<button
    mat-button
    *ngFor="let button of buttons"
    [class]="'custom-dialog__button ' + button.getClass()"
    [color]="button.color"
    [disabled]="button.disabledFn()"
    [style.visibility]="button.hiddenFn() ? 'hidden' : 'visible'"
    (click)="buttonClick.emit(button)"
>
    <mat-icon *ngIf="button.iconName && button.iconPosition === 'start'" [fontSet]="button.iconFontSet">{{
        button.iconName
    }}</mat-icon>
    <span *ngIf="button.label"> {{ button.label | translate }}</span>
    <mat-icon *ngIf="button.iconName && button.iconPosition === 'end'" [fontSet]="button.iconFontSet">{{
        button.iconName
    }}</mat-icon>
</button>
