<div class="custom-dialog__footer" *ngIf="config?.show">
  <app-custom-dialog-buttons-wrapper
    [buttons]="config.rightSideButtons"
    (buttonClick)="buttonClick($event)"
    class="custom-dialog__footer__buttons-wrapper"
  ></app-custom-dialog-buttons-wrapper>
  <app-custom-dialog-buttons-wrapper
    [buttons]="config.leftSideButtons"
    (buttonClick)="buttonClick($event)"
    class="custom-dialog__footer__buttons-wrapper"
  ></app-custom-dialog-buttons-wrapper>
</div>
